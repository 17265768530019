#root {
  overflow-x: hidden;
}

.paragraph {
  @apply text-xl mb-6 text-black2;
}

.p-button {
  @apply bg-secondary text-sm text-white py-3 px-6 rounded-full h-fit;
}

.s-button {
  @apply border-secondary border text-sm text-secondary py-3 px-6 rounded-full h-fit;
}
