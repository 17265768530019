.fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-in-second {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.fade-in.visible {
    opacity: 1;
    
}

.fade-in-second.visible-second {
    opacity: 1;
    
}


.destinationStyle .slick-slide div{
    height: 100vh;
}