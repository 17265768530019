/* general */
input,
select,
textarea {
  border: 0;
}

.tabs-container {
  width: 400px;
  margin: 20px auto;
}

.tabs {
  display: flex;
}

.tab {
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-bottom: none;
  background-color: #f1f1f1;
}

.tab.active {
  background-color: #ddd;
}

.tab-content {
  border: 1px solid #ddd;
  padding: 10px;
}

.testimonial .slick-list {
  overflow: visible;
  /* max-width: 100vw; */
}
.contactCarousel .slick-list {
  /* max-height: 52vh; */
  overflow-y: visible;
  min-height: 50vh;
  padding: 10vh 0 0 0;
}

/* contact page tabs */
.MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
  gap: 200px;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: black;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 25px;
  font-weight: 900;
}

/* contact page tabs mobile responsive */

@media (max-width: 700px) {
  button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-weight: 600;
    font-size: 12px;
  }
}

@media (max-width: 1400px) {
  .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
    gap: 0;
  }
}

.servicesCarousel .slick-dots li {
  width: 10vw !important;
}

.servicesCarousel ul.slick-dots.slick-thumb {
  bottom: 3;
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}

.servicesCarousel .slick-slide {
  margin: 0;
}

@media (max-width: 1200px) {
  .servicesCarousel .slick-dots li {
    width: 25vw !important;
  }
}

.playgroundCarousel .slick-dots li {
  width: 10vw !important;
}
/* 
.testimonial .slick-slide + div {
  @apply box-border mx-2;
} */

/* MUI creating a line under holiday desinations in contact page: fixed */

.MuiPaper-elevation1 {
  box-shadow: 0px 0px !important;
}

.MuiTabs-flexContainer {
  overflow-x: auto;
}

/* minor */
.inspiration-block {
  display: block !important;
}

/* .specialSlide .slick-slide {
  margin-left: 10px;
  margin-right: 10px;
  width: 14vw !important;
  margin-bottom: 20vh !important;
  margin-top: 10vh !important;
  transform: translateY(10vh);
} */

.specialSlide .slick-slide .block.rounded-2xl {
  /* width: 95% !important; */
  transform: scale(0.8);
  transform-origin: bottom right;
}

.specialSlide .slick-current {
  overflow: visible !important;
}
.specialSlide .slick-current .block.rounded-2xl {
  /* @apply transition-all duration-300 ease-in-out; */
  width: 20vw !important;
  /* transform: translateY(10vh);
  transform: scale(1.1); */
  transform-origin: top left;
  margin-top: 0vh !important;
  height: 48vh !important;
}

.specialSlide .slick-slide .block.rounded-2xl .image-overlay {
  position: relative;
}

.content-image-overlay .overlay {
  @apply rounded-xl;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  background: rgb(24, 24, 24);
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 0.8323704481792717) 0%,
    rgba(33, 33, 33, 0.45702030812324934) 45%,
    rgba(255, 255, 255, 0) 80%
  );
}
.sus-image-overlay .overlay {
  @apply rounded-xl;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(24, 24, 24);
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 0.8323704481792717) 0%,
    rgba(33, 33, 33, 0.45702030812324934) 45%,
    rgba(255, 255, 255, 0) 80%
  );
}
.specialSlide .slick-slide .block.rounded-2xl .image-overlay .overlay {
  @apply rounded-xl;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(25deg, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 50%);
}
.specialSlide .slick-current span {
  font-size: 24px;
}

.specialSlide .slick-current img {
  height: 50vh !important;
}

@media (max-width: 640px) {
  .specialSlide .slick-current img {
    width: 45vw !important;
  }
}

@media (max-width: 1024px) {
  .specialSlide .slick-current .block.rounded-2xl {
    transform-origin: bottom right;
    width: 95% !important;
    height: 40vh !important;
  }

  .specialSlide .slick-current img {
    height: 40vh !important;
  }

  .specialSlide .slick-current span {
    font-size: 16px;
  }
}

ul {
  @apply list-disc ml-5;
}

.blog h2 {
  font-size: 25px;
}

.blog td {
  padding: 10px;
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  .table-container table {
    width: 100%;
  }

  .table-container td {
    display: block;
    width: 100%;
  }

  .table-container tr {
    display: block;
    width: 100%;
  }
}

/* block imp */
.block-pls {
  display: block !important;
}

/* for content page (about us, privacy policy, etc) */
.content-data {
  @apply text-black;
}

/* Headings */
.content-data h1,
.content-data h2,
.content-data h3,
.content-data h4,
.content-data h5 {
  @apply pt-5 pb-1;
}
.content-data h1 {
  @apply text-2xl;
}
.content-data h2 {
  @apply text-xl;
}
.content-data h3,
.content-data h4,
.content-data h5 {
  @apply text-lg;
}

/* CarouselOverlay.css */

.carousel-overlay {
  @apply backdrop-blur-md;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.carousel-overlay .carousel-container {
  position: relative;
  width: 80%;
  max-width: 800px;
}

.carousel-overlay .close-button {
  @apply absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.carousel-overlay .carousel-container img {
  @apply w-full h-[60vh] object-cover;
}

/* phone number */
.react-international-phone-input-container .react-international-phone-input,
.react-international-phone-input-container,
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  height: 100% !important;
}
.react-international-phone-input-container .react-international-phone-input {
  @apply py-2.5 w-full bg-[#f3f4f8] !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  @apply py-2.5 px-3 !important;
}

.react-international-phone-input-container
  .react-international-phone-input:focus {
  @apply border border-gray-300 shadow-none ring-0 !important;
}

.react-international-phone-country-selector-dropdown {
  max-height: 120px !important;
}
