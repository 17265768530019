@media only screen and (min-width: 1280px) {
  .tourPackageStyles .slick-slide.slick-active.slick-current {
    @apply z-10;
    /* width: 35vw !important; */
    position: relative !important;
    transform: translateY(-19.2vh) translateX(-8.5vh);
    /* transform: translateY(-20vh) scale(2); */
  }
}
.tourPackageStyles .slick-list {
  overflow: visible;
}

.tourPackageStyles .slick-track {
  position: absolute;
  left: 10vw;
}
