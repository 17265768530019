.plane-loader .svg-calLoader {
  @apply mx-auto;
  width: 230px;
  height: 230px;
  transform-origin: 115px 115px;
  animation: 3s linear infinite loader-spin;
}
.plane-loader .cal-loader__plane {
  fill: #0c1538;
}
.plane-loader .cal-loader__path {
  stroke: #df5c26;
  animation: 3s ease-in-out infinite loader-path;
}
@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}
